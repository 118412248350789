import React, { useState } from 'react';
import "../css/navbar.css"
import logo from "../images/logofinal.png"

import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBIcon,
  MDBCollapse,
  MDBDropdownMenu,
  MDBDropdown,
  MDBDropdownItem,
  MDBDropdownToggle
} from 'mdb-react-ui-kit';

export default function Navbar() {
  const [showNavSecond, setShowNavSecond] = useState(false);

  function handleMouseEnter(){
    // Show the dropdown menu when the mouse enters
    // const dropdownMenu = document.getElementById('products-dropdown');
    // dropdownMenu.style.display = 'block';
    console.log("Hello")
  };


  function handleMouseLeave(){
    // Hide the dropdown menu when the mouse leaves
    // const dropdownMenu = document.getElementById('products-dropdown');
    // dropdownMenu.style.display = 'none';
  };
  
  return (
  <div>
          {/* <iframe style={{zIndex:"20",position:"absolute",display:"flex",width:"100%",margin:"0px",top:"0px"}} src="https://embed.lottiefiles.com/animation/32896"></iframe> */}

	<div className='nav-stick'> 
 
  <a  href="/brochure.pdf" download="brochure.pdf">
	 <div style={{textAlign:"right"}} className='brochure'>
   
	 <img   width="25" height="25" className='disp' src={require("../images/downloadlogo.png")} alt="download"/>
	 <h6 className='disp disp-content' style={{marginRight:"20px",fontFamily:"fantasy"}}><a  href="/brochure.pdf" download="brochure.pdf">Our Brochure</a></h6>
	 </div>
   </a>


    <MDBNavbar expand='lg' light style={{backgroundColor:"#FFECEF"}}>
      <MDBContainer fluid>
        <MDBNavbarBrand  href='/'><img src={logo} style={{width:"250px",height:"70px"}}></img></MDBNavbarBrand>
        <MDBNavbarToggler
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowNavSecond(!showNavSecond)}
        >
          <MDBIcon icon='bars' fas />
        </MDBNavbarToggler>
        <MDBCollapse   navbar show={showNavSecond} center id='navbarCenteredExample' >
          <MDBNavbarNav right  fullWidth={false} className='mb-2 mb-lg-0'>
            <MDBNavbarLink className='nav-items-content' style={{fontSize:"20px",color: "rgb(60, 5, 81)"}} active aria-current='page' href='/'>
              Home
            </MDBNavbarLink>
            <MDBNavbarLink className='nav-items-content element' style={{fontSize:"20px",color: "rgb(60, 5, 81)",cursor:"pointer"}} href="/about"  tabIndex={-1} aria-disabled='true'>
              About Us
            </MDBNavbarLink>
            {/* <MDBNavbarLink className='nav-items-content' style={{fontSize:"20px",color: "rgb(60, 5, 81)"}} href='#'>About Us</MDBNavbarLink> */}
            
            <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link nav-items-content' style={{fontSize:"20px",color: "rgb(60, 5, 81)"}}>
                  Products
                </MDBDropdownToggle>
                <MDBDropdownMenu id="products-dropdown" style={{"background":"#26b1bf"}}>
                  <MDBDropdownItem link href='/products/0'>Transparent Ink</MDBDropdownItem>
                  <MDBDropdownItem link href='/products/1'>Matt Ink</MDBDropdownItem>
                  <MDBDropdownItem link href='/products/2'>Polybond Ink</MDBDropdownItem>
                  <MDBDropdownItem link href='/products/3'>Roto Online Ink</MDBDropdownItem>
                  <MDBDropdownItem link href='/products/4'>PVC Gloss Ink</MDBDropdownItem>
                  <MDBDropdownItem link href='/products/5'>PVC Ink</MDBDropdownItem>
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>

            <MDBNavbarItem>
              <MDBDropdown>
                <MDBDropdownToggle tag='a' className='nav-link nav-items-content'  style={{fontSize:"20px",color: "rgb(60, 5, 81)"}}>
                  Specality Inks
                </MDBDropdownToggle>
                <MDBDropdownMenu style={{"background":"#26b1bf",color:"black"}}>
                  <MDBDropdownItem link href='/products/6'>PVC Fluorescent Ink</MDBDropdownItem>
                  <MDBDropdownItem link href='/products/7'>Gold Ink</MDBDropdownItem>
                  <MDBDropdownItem link href='/products/8'>Silver Ink</MDBDropdownItem>
                  

                  
                </MDBDropdownMenu>
              </MDBDropdown>
            </MDBNavbarItem>
           
			
            <MDBNavbarLink className='nav-items-content' style={{fontSize:"20px",color: "rgb(60, 5, 81)",cursor:"pointer"}} href='/aux' tabIndex={-1} aria-disabled='true'>
               Auxiliaries
            </MDBNavbarLink>
            <MDBNavbarLink className='nav-items-content' style={{fontSize:"20px",color: "rgb(60, 5, 81)",cursor:"pointer"}} href='#footer' tabIndex={-1} aria-disabled='true'>
              Contact Us
            </MDBNavbarLink>
          </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
	</div>
  </div>
  );
}

export const products=[
    {
        "name":"Transparent Ink",
        "description":"Transparent (TR) Ink is a solvent-based type of ink which is produced using high quality chemicals. This ink gives a high glossy finish to the substrate, having fast drying property. This Ink has main purpose of printing on substrates such us transparent papers, foil sheet, glass printing, Aluminium sheets/foils and lamination sheet.",
        "keyfeatures":["High gloss finish","Fast drying","Free flowing screen ink"],
        "diluent":["TR Ink Aid"],
        "colors":"We offer a comprehensive range of colours, encompassing all standard shades, while also accommodating customized colour options based on the specific preferences of our customers.",
        "index":"0"
    },
    {
        "name":"Matt Ink",
        "description":"Matt ink is an oil-based type of inks, which is used to print on the substrates like non-woven cloth, paper printing, paper bag, corrugated boxes and any other paper substrate. This ink gives non-shiny matt finish output.",
        "keyfeatures":["Fast dying","Matt Finish","Free flowing screen ink"],
        "diluent":["Matt Ink Aid"],
        "colors":"We offer a comprehensive range of colours, encompassing all standard shades, while also accommodating customized colour options based on the specific preferences of our customers.",
        "index":"1" 
    },
    {
        "name":"Polybond Ink",
        "description":"Polybond inks-PB inks are specially formulated oil solvent based quick dying inks to print packing containers & articles of polyethylene, HDPE, polypropylene which includes water tanks and all kind of plastic substrate. The surface of these substrates should be pre-treated by flaming or corona discharge so as to get surface tension which is necessary for the adhesion of the ink.",
        "keyfeatures":["Polybond inks offers excellent finish.",
            "Excellent Adhesion on treated Substrates.",
            "Smooth high matt satin finish\ gloss finish",
            "Free Flowing Ink",
            "We manufacture special type of polybond Inks used for printing on Polypropylene (PP) substrates in unique finish such as:",
            "     PP Polybond Matt Finish",
            "     PP Polybond Gloss Finish"
            ],
        "diluent":["Polybond Ink Aid"],
        "colors":"We offer a comprehensive range of colours, encompassing all standard shades, while also accommodating customized colour options based on the specific preferences of our customers.",
        "index":"2" 
    },
    {
        "name":"Rotor Online Ink",
        "description":"Rotor online ink or PVC pipe printing ink used to print on PVC substrates which includes cPVC, uPVC and more. This is a solvent based type of ink. This gives a matt finish as an output. These are suitable for automatic PVC pipe printing machines.",
        "keyfeatures":["Good adhesion on the substrate",
            "Matt finish",
            "Fast dying",
            "Free Flowing Ink"
            ],
        "diluent":["Fast Roto Online Ink Aid",
            "Slow Roto Online Ink Aid"
            ],
        "additional_product":"ROTO ONLINE MEDIUM:This is specially used in occasions where; we need to modify the ink and acquire more coverage or used for the correction of the inks.",
        "colors":"We offer a comprehensive range of colours, encompassing all standard shades, while also accommodating customized colour options based on the specific preferences of our customers.",
        "index":"3" 
    },
    {
        "name":"PVC Gloss Ink",
        "description":"PVC (Poly Vinyl Chloride) Ink is a solvent-based type of ink which is made up of superior quality chemicals. This Ink gives a glossy finish output on the substrate and has fast drying property. This Ink has main purpose of printing on substrates such as chromo art paper, leather bags, slippers and other substrate which need glossy finish.",
        "keyfeatures":["Good Adhesion on the Substrate",
            "Glossy finish",
            "Fast Drying",
            "Free Flowing Ink"
            ],
        "diluent":["PVC Gloss Ink Aid"],
        "colors":"We offer a comprehensive range of colours, encompassing all standard shades, while also accommodating customized colour options based on the specific preferences of our customers.",
        "index":"4" 
    },
    {
        "name":"PVC Ink",
        "description":"PVC (Poly Vinyl Chloride)Ink is a solvent-based type of inkwhich is made up of superior quality chemicals. This Ink gives a matt finish output on the substrate and has fast drying property on the substrate. Our PVC Screen Printing Ink can be used on any paper substrate to provide impressions that last.This Ink has main purpose of printing on substrates such as PVC pipes of Water pipes and Electric Pipes (Manually Printing),leather bags,slipper printing,non-woven bags, rexin cloth and any other paper substrates.",
        "keyfeatures":["Good Adhesion on the Substrate",
            "Matt Finish",
            "Fast Drying",
            "Free Flowing Ink"
            ],
        "diluent":["PVC Ink Aid",
            "Super Ink Aid"
            ],
        "colors":"We offer a comprehensive range of colours, encompassing all standard shades, while also accommodating customized colour options based on the specific preferences of our customers.",
        "index":"5" 
    },
    {
        "name":"PVC FLUORESCENT INK",
        "description":"PVC (Poly Vinyl Chloride) fluorescent Ink is a solvent-based type of ink which is made up of superior quality chemicals. This Ink gives a fluorescent colour effect on the substrate such as slippers, leather bags, paper printing and any other paper substrate.",
        "keyfeatures":["Good Adhesion on the Substrate",
            "Fluorescent Shade finish",
            "Fast Drying",
            "Free Flowing Ink"
            ],
        "diluent":[
            "PVC Ink Aid",
            "Super Ink Aid"

            ],
        "colors":"We offer a comprehensive range of colours, encompassing all standard shades such as Pink, Orange, Green, Lemon Yellow.",
        "index":"6" 
    },
    {
        "name":"GOLD INK",
        "description":"It’s a special type of PVC screen Ink having same properties of PVC ink providing glossy finish on the substrate with gold shade outcome on the substrate.",
        "keyfeatures":[
            "Good adhesion on the substrate",
            "Glossy and Gold Finish",
            "Fast drying",
            "Free flowing ink"

            ],
        "diluent":[
            "PVC Ink Aid",
            "Super Ink Aid"

            ],
        "shades":"V9 gold ink , Yellow gold ink ,Copper gold ink",
        "index":"7" 
    },
    {
        "name":"SILVER INK",
        "description":"It’s a special type of PVC screen Ink having same properties of PVC ink providing glossy finish on the substrate with silver shade outcome on the substrate.",
        "keyfeatures":[
            "Good adhesion on the substrate",
            "Glossy and slivery finish",
            "Fast drying",
            "Free flowing ink"

            ],
        "shades":"Sliver Shade",
        "index":"8" 
    }
]
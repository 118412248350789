// import React from 'react'
import React, { useRef, useEffect } from 'react';
import "../css/viewpage.css"
import Product from './Product'
import { products } from '../products_info/products'
import Clients_logos from './clients'
function ViewPage(props) {

  const targetSectionRef = useRef();
  useEffect(() => {
    // Scroll to the target section when the component mounts
    if (targetSectionRef.current && props.id) {
      targetSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <div className='view1' style={{textAlign:"center"}}>
      <h1 style={{textAlign:"center",marginTop: "25px",color:"#0f296d"}}>Welcome to Vanavil Inko Industries</h1>
          <p style={{
  textAlign: "justify",
  color:"#black",
  fontSize:"1.2rem",
  margin: "0 5%",
  padding: "20px" /* Add a subtle shadow */
}}>Discover a world of vibrant colors and unmatched quality in our premium screen printing ink collection. From bold hues to subtle shades, explore a spectrum of possibilities designed to fuel your creativity. Unleash your imagination with our inks that make a positive impact. Our user-friendly website and dedicated support ensure a seamless experience. Join our community of inks and enthusiasts, staying updated on the latest trends. Elevate your expression with Vanavil Inko Industries today!</p>
      {/* <hr></hr> */}
     <div >
        <h2 style={{textAlign:"center",marginTop: "25px",color:"#0f296d"}}>Vision</h2>
        <p style={{
  textAlign: "center",
  color:"#black",
  fontSize:"1.2rem",
  margin: "0 5%",
  padding: "20px" /* Add a subtle shadow */
}}>To revolutionize the printing industry by providing high-quality, sustainable, and innovative ink solutions that enable our customers to bring their ideas to life, while reducing their environmental impact and contributing to a more sustainable future.
</p>
     </div>


     {/* <hr></hr> */}
     <div >
        <h2  ref={targetSectionRef} style={{textAlign:"center",marginTop: "25px",color:"#0f296d"}}>Mission</h2>
        <p style={{
  textAlign: "center",
  color:"#black",
  fontSize:"1.2rem",
  margin: "0 5%",
  padding: "20px" /* Add a subtle shadow */
}}>Our mission is to provide high-quality, sustainable printing ink solutions that meet the evolving needs of our customers. We continously work on innovation, technology and developement to provide a extraodinary products to our dear customer.</p>
     </div>

     {/* <hr></hr> */}
     <div >
        <h2 style={{textAlign:"center",marginTop: "25px",color:"#0f296d"}}>Objective</h2>
        <p style={{
  textAlign: "center",
  color:"#black",
  fontSize:"1.2rem",
  margin: "0 5%",
  padding: "20px" /* Add a subtle shadow */
}}>Our Objective is to provide our customer good product to their need under a probitable price and give solutions to their problems. We are working on providing a eco friendly ink to ensure the safely our environment.</p>
     </div>

     {/* <hr></hr> */}
      
<hr></hr>

      <div>
      <h2 style={{textAlign:"center",marginTop: "25px",color:"#0f296d"}}>Our Clients</h2>
      <Clients_logos />
      <br></br>
      </div>
      
    </div>
  )
}


export default ViewPage;
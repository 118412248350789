import React from 'react';
import "../css/viewpage.css"
// import {
//   MDBCarousel,
//   MDBCarouselItem,
// } from 'mdb-react-ui-kit';

// export default function Clients_logos() {
//   return (
//     <MDBCarousel style={{marginTop:"10px"}} showControls showIndicators>
//       <MDBCarouselItem
//        data-mdb-interval="10000"
//         className='w-20 d-block'
//         itemId={1}
//         src={require("../images/clients_logos/1.jpeg")}
//         alt='...'
//       />
//       <MDBCarouselItem
//        data-mdb-interval="10000"
//         className='w-20 d-block'
//         itemId={2}
//         src={require("../images/clients_logos/3.jpeg")}
//         alt='...'
//       />
//       <MDBCarouselItem
//       data-mdb-interval="10000"
//         className='w-20 d-block'
//         itemId={3}
//         src={require("../images/clients_logos/2.jpeg")}
//         alt='...'
//       />
      
//        <MDBCarouselItem
//        data-mdb-interval="10000"
//         className='w-20 d-block'
//         itemId={3}
//         src={require("../images/clients_logos/5.jpeg")}
//         alt='...'
//       />
//       <MDBCarouselItem
//       data-mdb-interval="10000"
//         className='w-20 d-block'
//         itemId={3}
//         src={require("../images/clients_logos/0.jpeg")}
//         alt='...'
//       />
//     </MDBCarousel>
//   );
// }



import Slider from 'react-infinite-logo-slider'

const Component = () => {
    
    return (
        <Slider
            
            duration={19}
            pauseOnHover={true}
            blurBorders={false}
            blurBoderColor={'#fff'}
        >
            <Slider.Slide className="slider">
                <img  src={require("../images/clients_logos/tech.png")} alt="any1" className='w-26' />
            </Slider.Slide>
            <Slider.Slide className="slider">
                <img src={require("../images/clients_logos/kundan.png")} alt="any3" className='w-26' />
            </Slider.Slide>
            <Slider.Slide className="slider">
                <img src={require("../images/clients_logos/meera.png")} alt="any2" className='w-26' />
            </Slider.Slide>
            <Slider.Slide className="slider">
                <img src={require("../images/clients_logos/0.jpeg")} alt="any4" className='w-26' />
            </Slider.Slide>
            
            {/* <Slider.Slide className="slider">
                <img  style={{margin:"100px"}} src={require("../images/clients_logos/0.jpeg")} alt="any3" className='w-36' />
            </Slider.Slide> */}
        
            
        </Slider>
       
    )
}              
                     
export default Component
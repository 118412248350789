import React from 'react'
import "../css/viewpage.css"
import "../css/auxi.css"
import {
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn
  } from 'mdb-react-ui-kit';
import Product from './Product';

export default function Auxi(){
    return (<div style={{textAlign:"center",margin:"auto"}}>
        <div className='filler'></div>
        {/* <div class="container" style={{textAlign:"center"}}>

            <div class="row">
              <div  class="col-sm">
                <img className="im" src={require('../images/auxi/0.png')}></img>
                <h4>Vanavil 100 </h4>
                <p>It is Screen Remover made using specialized chemicals mainly for cleaning the exposed screen and make it fresh for reuse.</p>
              </div>
              <div class="col-sm">
              <img className="im" src={require('../images/auxi/1.png')}></img>
                <h4>Vanavil Poly Blue</h4>
                <p>It is a coating material used for exposing the desired design on the screen cloth for printing process</p>
              
              </div>
            </div>

            <div class="row">
              <div class="col-sm"><img className="im" src={require('../images/auxi/2.png')}></img>
                <h4>DTP Toner Darker</h4>
                <p>It is a tone changer for darker printing</p></div>
              <div class="col-sm"><img className="im" src={require('../images/auxi/3.png')}></img>
                <h4>Drier</h4>
                <p>It is a chemical used to make fasten up the drying property.</p></div>
            </div>
        </div> */}

        <div class="container" style={{textAlign:"center"}}>

            <div class="row">
            <div  class="col-sm">
        <Product id="0" name="Vanavil 100" description="It is Screen Remover made using specialized chemicals mainly for cleaning the exposed screen and make it fresh for reuse." />
        </div>
        <div  class="col-sm"> <Product id="1" name="Vanavil Poly Blue" description="It is a coating material used for exposing the desired design on the screen cloth for printing process" />
</div>
        <div class="row">
        <div  class="col-sm">        <Product id="2" name="DTP Toner Darker" description="It is a tone changer for darker printing" />
</div>
        <div  class="col-sm">        <Product id="3" name="Drier" description="It is a chemical used to make fasten up the drying property" />
</div>
        </div>
        </div>
        </div>
        <br></br>
    </div>
    )
}
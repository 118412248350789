import React from 'react'
import ViewPage from './ViewPage'
import Carousul_image  from "./Carousul_image"
import "../css/viewpage.css"
export default function Home(props) {
  return (
    <div>
        {/* <iframe style={{zIndex:"20",position:"absolute",display:"flex",width:"100%",alignItems:"stretch",margin:"0px",height:"100vh"}} src="https://embed.lottiefiles.com/animation/32896"></iframe> */}
    
      <div className='filler'></div>
        <Carousul_image />
        <ViewPage id={props.route}/>
    </div>
  )
}

import React from 'react';
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn
} from 'mdb-react-ui-kit';
import "../css/auxi.css"

export default function Product(props) {
  return (
   
    <MDBCard style={{margin:"10% 0 0 22%",width:"60%"}}>
      <MDBCardImage className='im' src={require("../images/auxi/"+props.id+".png")} position='top' alt='...' />
      <MDBCardBody style={{backgroundColor:"##38127fc1",color:"white"}}>
        <MDBCardTitle style={{color:"black"}}>{props.name}</MDBCardTitle>
        <MDBCardText style={{color:"gray"}}>
          {props.description}
        </MDBCardText>
      </MDBCardBody>
    </MDBCard>
  );
}
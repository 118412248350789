import './App.css';
import React, { useState } from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import ProductsPage from './components/ProductsPage'

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import Product from './components/Product';
import Auxi from './components/Auxi';
import About from './components/About';
function App() {
  return (
  <div>
  
    <Router>
      <Navbar />
       <Routes>
        <Route path='' element={<Home route={0} />} />
        <Route path='/products/:id' element={<ProductsPage />} />
        <Route path='/aux' element={<Auxi />} />
        <Route path='/about' element={<About />} />
        
      </Routes> 
      <Footer />
    </Router>
  
      
  </div>

  );
}

export default App;

import React from 'react'
import { InputGroup } from 'react-bootstrap'
import "../css/about.css"

export default function About() {
  return (
    <div>
        <div className='filler'></div>
        <div style={{textAlign:"center"}}>
            <br></br>
            <h1>About Us</h1>
            <div class="container">
            <div class="row">
                <div class="col-sm con"  >
                    {/* <h4>Founder</h4> */}
                    <img className='profile'  alt='founder'></img>
                    <h4>A.Duraipandi, Founder</h4>
                </div>
                <div class="col-sm">
                    <p className='pa'>
                    Welcome to Vaanavil inko industries, your trusted partner in the world of inks. I am A.Duraipandi, the proud founder of this remarkable journey that began 2019. Our mission was clear from the start - to redefine the ink industry through innovation, quality, and sustainability.
                    Having spent 29 years in the ink manufacturing industry, I recognized a gap in the market for inks that not only met the highest industry standards but also embraced environmental responsibility. This vision gave birth to Vaanavil Inko Industries, where we merge tradition with technology to create inks that empower businesses while preserving the planet.
                    Our relentless commitment to research and development has allowed us to craft a diverse portfolio of inks suitable for various applications, from traditional printing to cutting-edge digital processes. We understand that your success hinges on the quality of our products, and we take that responsibility seriously.All these 29 years with these changing generations I have been grateful to my customers and loyal to my profession. We as a team ensure you on availing customizable quality products.

                    </p>
                </div>
            </div>
            {/* <br></br> */}
            <div class="row">
            <div class="col-sm " style={{padding: "5% 0 0 0"}}>
                    {/* <h4>Managing Director</h4> */}
                    <img className='profile'  alt='Managing Director'></img>
                    <h4>D.Baskara Pandian, Managing Director</h4>
                    
                </div>
                <div class="col-sm" >
                <p className='pa'>
                As the Managing Director of Vaanavil Inko Industries, I am honored to continue the legacy initiated by our visionary founder. Over the years, our team has diligently worked to ensure that our products not only meet but exceed industry standards. We strive for excellence in every aspect of our business, from manufacturing to customer service.

At Vaanavil Inko Industries, sustainability is not just a buzzword; it's a core principle. Having done over 1000+ projects we are heading forward to offer services at our customer's wishes. By choosing Vaanavil Inko Industries, you're not only benefiting from superior ink solutions but also contributing to a greener future.

Our success is intrinsically tied to the success of our clients. We take pride in being more than just an ink supplier; we are your partners in progress. Our dedicated team is always ready to provide personalized solutions to meet your specific needs and challenges.

                    </p>
                </div>
                
            </div>
                
            </div>
        
        </div>
    </div>
  )
}


import React,{ useEffect, useState } from 'react'
import "../css/viewpage.css"
import {products} from "../products_info/products.js"
import "../css/productspage.css"
import { useParams } from "react-router-dom";

export default function ProductsPage(props) {
  const { id } = useParams();
  if(id=="7"){
    return (
      <div>
        <div className='filler'></div>
        <br></br>
        <br></br>
        <h1 style={{textAlign:"center",marginBottom:"1%"}}>{products[id].name}</h1>
        <div class="container">
          <div class="row">
            <div class="col-sm">
              <SampleDiv id={id} />
            </div>
            <div class="col-sm">
            <div style={{textAlign:"center"}}>
        <div style={{padding:"0px 20px 20px 20px"}}>
        {/* <h5>About</h5> */}
        <p style={{"fontSize":"1.2rem"}}>{products[id].description}</p>
        </div>
        <hr ></hr>
        <div style={{padding:"30px"}}>
        
        <h5>Key Features</h5>
        <br></br>
          {products[id].keyfeatures.map((e,ind)=>{
            if(ind==4) return <h6>{e}</h6>
            else return <p>{e}</p>})}
        </div>
        <hr ></hr>
        <div style={{padding:"30px"}}>
        <h5>Diluent</h5>
        <br></br>
          {products[id].diluent.map((e)=>{return  <p>{e}</p>})}
        </div>
        <hr ></hr>
        <div style={{padding:"30px"}}>
        <h5>Shades</h5>
        <br></br>
          {products[id].shades}
        </div>
        </div>
        <hr ></hr>
            </div>
            
          </div>
        </div>
    </div>
    )
  }
  else if(id=="8"){
    return (
    <div>
    <div className='filler'></div>
        <br></br>
        <br></br>
        <h1 style={{textAlign:"center",marginBottom:"1%"}}>{products[id].name}</h1>
        <div class="container">
          <div class="row">
            <div class="col-sm">
              <SampleDiv id={id} />
            </div>
            <div class="col-sm">
            <div style={{textAlign:"center"}}>
        <div style={{padding:"0px 20px 20px 20px"}}>
        {/* <h5>About</h5> */}
        <p style={{"fontSize":"1.2rem"}}>{products[id].description}</p>
        </div>
        <hr ></hr>
        <div style={{padding:"30px"}}>
        
        <h5>Key Features</h5>
        <br></br>
          {products[id].keyfeatures.map((e,ind)=>{
            if(ind==4) return <h6>{e}</h6>
            else return <p>{e}</p>})}
        </div>
        <hr ></hr>
        <div style={{padding:"30px"}}>
        <h5>Shades</h5>
        <br></br>
          {products[id].shades}
        </div>
        </div>
        <hr ></hr>
            </div>
            
          </div>
        </div>

        
    </div>
    )
  }
  else{
  return (
    <div>
        <div className='filler'></div>
        <br></br>
        <br></br>
        <h1 style={{textAlign:"center",marginBottom:"1%"}}>{products[id].name}</h1>
        <div class="container">
          <div class="row">
            <div class="col-sm">
              <SampleDiv id={id} />
            </div>
            <div class="col-sm">
            <div style={{textAlign:"center"}}>
        <div style={{padding:"0px 20px 20px 20px"}}>
        {/* <h5>About</h5> */}
        <p style={{"fontSize":"1.2rem"}}>{products[id].description}</p>
        </div>
        <hr ></hr>
        <div style={{padding:"30px"}}>
        
        <h5><b>Key Features</b></h5>
        <br></br>
          {products[id].keyfeatures.map((e,ind)=>{
            if(ind==4) return <h6><b>{e}</b></h6>
            else return <p>{e}</p>})}
        </div>
        <hr ></hr>
        <div style={{padding:"30px"}}>
        <h5><b>Diluent</b></h5>
        <br></br>
          {products[id].diluent.map((e)=>{return  <p>{e}</p>})}
        </div>
        <hr ></hr>
        <div style={{padding:"30px"}}>
        <h5><b>Colors</b></h5>
        <br></br>
          {products[id].colors}
        </div>
        </div>
        <hr ></hr>
            </div>
            
          </div>
        </div>
    </div>
  )
          }
}



const SampleDiv = (prop) => {
  const id=prop.id
  return (
    <div
      style={{ textAlign:"center",padding:"40px"}}
    >
      
            <img style={{
         width: "35vw",
    height: "auto",
    display: "block",
    margin: "auto",
    filter: "drop-shadow(20px 20px 10px gray)",
        }} src={require("../images/products1/"+products[id].index+".png")}  alt="Transparent Ink"></img>
            

    </div>
   
  );
};


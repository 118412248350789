import React from 'react';
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import "../css/footer.css"
export default function Footer() {
  return (
    <div id="footer">
    
    <MDBFooter bgColor='light' className='text-center text-lg-start text-muted'>
      {/* <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
          <div >
          <a  href='https://www.facebook.com/people/Vanavil-Inko-Industries/100092281849958/' className='me-4 text-reset'>
            
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='https://www.youtube.com/@Vanavil_Ink' className='me-4 text-reset'>
            <MDBIcon fab icon="youtube" />
          </a>
        </div>
        </div>

        <div >
          <a  href='https://www.facebook.com/people/Vanavil-Inko-Industries/100092281849958/' className='me-4 text-reset'>
            
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='https://www.youtube.com/@Vanavil_Ink' className='me-4 text-reset'>
            <MDBIcon fab icon="youtube" />
          </a>
        </div>
      </section> */}

      <section className=''>
      <hr></hr>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-3" />
                Vanavil Inko Industries
              </h6>
              <p>
             <i> We keep our customers vibrant, bright as rainbow</i>
              </p>
              <div >
              <br></br>
              <span>Get connected with us on social networks:</span><br></br>
          <a  href='https://www.facebook.com/people/Vanavil-Inko-Industries/100092281849958/' className='me-4 text-reset'>
            
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='https://www.youtube.com/@Vanavil_Ink' className='me-4 text-reset'>
            <MDBIcon fab icon="youtube" />
          </a>
        </div>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
                <MDBIcon icon="home" className="me-2" />
                131,Aruppukkottai Main Road, Near High school stop,
                Avaniyapuram, Madurai(TN) -625 012.<br></br>
      
              </p>
              <p>
                <MDBIcon icon="envelope" className="me-3" />
                vanavilinkoindustries19@gmail.com<br></br>
                <MDBIcon icon="envelope" className="me-3" />vanavilinksoffice@gmail.com
              </p>
              <p>
                <MDBIcon icon="phone" className="me-3" /> +91 9442461964
                <br></br>
                <MDBIcon fab icon="whatsapp" size='lg'  className="me-3"  /> +91 7373787817/18
              </p>
      
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        © 2023 Copyright:
        <a className='text-reset fw-bold' href=''>
          Vanavil Inko Industries
        </a>
      </div>
    </MDBFooter>
    </div>
  );
}